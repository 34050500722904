.countdown-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.countdown {
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #11998e;
  transform: rotate(90deg);
}

.countdown-progress {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  background: transparent;
  border: 2px solid #11998e;
  transform-origin: 0 50%;
  transform: rotate(0deg);
  transition: transform 1s linear;
}

.countdown-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #333;
}
