@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500&family=Outfit:wght@100;300;400;500;700&family=Quicksand:wght@400;500;700&display=swap");
body {
    font-family: "Outfit", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* react slick */
.slick-dots {
    top: 85%;
}

.slick-list {
    margin: 0 -10px;
}

/* .steps .step:after {
  background-color: #11998e;
  color: white;
} */
.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 6px;
}

section {
    max-width: 1280px;
    margin: 24px auto 40px;
    padding: 0 6px;
}
